<template>
	<DialogBase
		scrollable
		:dialogWidth="800"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
	>
		<template v-slot:title>
			{{ selectedModel ? `Update ${selectedModel.name}` : "Create New" }}
			MGA Company
		</template>
		<v-form
			id="create-update-mga-company-dialog"
			@submit.prevent
			v-model="isValidForm"
			ref="from"
		>
			<div class="pa-2 pa-2">
				<VFormBase
					@input="handleFormInputClick"
					@click="handleFormInputClick"
					:schema="form_structure"
					:model="form_data"
				>
					<!-- <div
					slot="slot-tooltip"
					slot-scope="slotProps"
				>
					Tooltip-Slot: {{ slotProps.obj.schema.tooltip }} has value '{{ slotProps.obj.value || 'undefined' }}'
				</div> -->
				</VFormBase>
			</div>
			<ImageUploadBtn
				:btnText="`${selectedModel ? 'Change ' : 'Upload '}Company Logo Image`"
				:previewImageSrc="
					selectedModel ? selectedModel.company_logo_image_url : null
				"
				@done="image => (editedCompanyLogoImage = image)"
				:imageWidth="212"
				:imageHeight="27"
			></ImageUploadBtn>
			<ImageUploadBtn
				:btnText="
					`${selectedModel ? 'Change ' : 'Upload '}Agent Portal Logo Image`
				"
				:previewImageSrc="
					selectedModel ? selectedModel.agent_portal_logo_image_url : null
				"
				@done="image => (editedAgentPortalLogoImage = image)"
				:imageWidth="300"
				:imageHeight="35"
			></ImageUploadBtn>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">{{
				selectedModel ? "Update" : "Create"
			}}</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { mapState } from "vuex";
	import { required, minLen } from "@/plugins/validationRules.js";
	import VFormBase from "vuetify-form-base";
	import DialogBase from "@/components/dialogs/Base";
	import ImageUploadBtn from "@/components/btns/ImageUploadBtn";
	export default {
		components: { DialogBase, VFormBase, ImageUploadBtn },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				editedCompanyLogoImage: null,
				editedAgentPortalLogoImage: null,
				isValidForm: false,
				form_data: {
					is_active: "1",
					name: null
				}
			};
		},
		mounted() {
			if (this.selectedModel) {
				let selectedModel = JSON.parse(
					JSON.stringify(Object.assign({}, this.selectedModel))
				);

				selectedModel["is_active"] = `${selectedModel["is_active"]}`;

				this.form_data = Object.assign({}, selectedModel);
			} else {
			}
		},
		computed: {
			form_structure() {
				let structure = {
					is_active: {
						type: "switch",
						name: "is_active",
						label: `Active : ${this.form_data.is_active == "1" ? "Yes" : "No"}`,
						...this.$helpers.formFieldsBasicLayout,
						inset: true,
						trueValue: "1",
						falseValue: "0",
						rules: [required("Field is required.")]
					},
					name: {
						type: "text",
						name: "name",
						label: "Name",
						...this.$helpers.formFieldsBasicLayout,
						rules: [required("Field is required.")]
					}
				};

				return structure;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			async handleFormInputClick({
				on,
				id,
				key,
				value,
				params,
				obj,
				data,
				schema,
				parent,
				index,
				event
			}) {
				// console.log(
				// 	on,
				// 	id,
				// 	key,
				// 	value,
				// 	params,
				// 	obj,
				// 	data,
				// 	schema,
				// 	parent,
				// 	index,
				// 	event
				// );
				// console.log(`${on} ${id} ${key} ${value}`);
				// console.log(on + "," + id + "," + key + "," + params.tag);
			},

			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let formData = JSON.parse(
					JSON.stringify(Object.assign({}, this.form_data))
				);

				let data = {
					...formData,
					is_active: formData.is_active == "1" ? 1 : 0,
					company_logo_image: this.editedCompanyLogoImage?.imageFile,
					agent_portal_logo_image: this.editedAgentPortalLogoImage?.imageFile,
					session_key: this.sessionKey
				};

				data = this.$helpers.jsonToFormData(data);

				return await this.post(
					this.baseUrl + `/create-update-mga-company`,
					data,
					true
				).then(data => {
					if (data) {
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.$emit("refreshList");
							this.$emit("created", data.data.mga_company);
						}
					}
				});
			}
		}
	};
</script>

<style lang="scss">
</style>
